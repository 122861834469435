import React, { useState } from "react";
import Head from 'next/head';
import { ConfigProvider, Image, Layout, Menu, MenuProps } from "antd";
import { faBell, faSignIn, faSignOut, faUserGear } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRouter } from "next/router";
import { signIn, signOut, useSession } from "next-auth/react";
import nProgress from "nprogress";
import MyBreadcrumb from "./Breadcrumb";
import Link from "next/link";

const { Sider } = Layout;

const DefaultLayout: React.FC<{
    children: React.ReactNode
}> = ({ children }) => {
    const router = useRouter();
    const { data: session, status } = useSession();
    const isAdmin = session?.user?.['role'][0] == "Administrator";

    // menu.key must match the router.pathname, see example below: "/dashboard"
    const [selected, setSelected] = useState([router.pathname]);

    // key must also be unique, for obvious reason
    const handleLogout = () => {
        if (status === 'authenticated') {
            nProgress.start();
            signOut({
                callbackUrl: '/api/end-session',
            });
        }
    }

    function getMenu(): MenuProps['items'] {
        const menu: MenuProps['items'] = [];

        if (isAdmin) {
            menu.push(
                {
                    key: '#menu-1',
                    label: <div className="text-white font-inter font-medium">Licensing Server</div>,
                    children: [
                        {
                            key: '/dashboard',
                            label: 'Dashboard',
                            onClick: () => router.push('/dashboard')
                        },
                        {
                            key: '/customer-management',
                            label: 'Customer Management',
                            onClick: () => router.push('/customer-management')
                        },
                        {
                            key: '/license-management',
                            label: 'License Management',
                            onClick: () => router.push('/license-management')
                        },
                        {
                            key: '/license-reminder',
                            label: 'License Reminder History',
                            onClick: () => router.push('/license-reminder')
                        },
                        {
                            key: 'hangfire',
                            label: 'Hangfire Dashboard',
                            onClick: () => window.open(`${process.env['NEXT_PUBLIC_OIDC_ISSUER']}/hangfire`, '_self')
                        }
                    ]
                }
            );
        }
        else {
            menu.push(
                {
                    key: '#menu-2',
                    label: <div className="text-white font-inter font-medium">Licensing Server</div>,
                    children: [
                        {
                            key: '/license-status',
                            label: 'License Status',
                            onClick: () => router.push('/license-status')
                        },
                    ]
                }
            );
        }
        return menu;
    }

    const link = isAdmin ? './dashboard' : './license-status';
    return (
        <>
            <div className="flex">
                <ConfigProvider theme={{
                    components: {
                        Layout: {
                            // Sidebar background color:
                            // https://github.com/ant-design/ant-design/blob/5.0.0/components/layout/style/index.tsx#L101
                            headerBg: "red",
                            siderBg: 'white',
                        }
                    }
                }}>
                    <Layout className="min-h-screen">
                        <Head>
                            <meta key="meta-charset" charSet="utf-8" />
                            <meta key="meta-viewport" name="viewport" content="width=device-width, initial-scale=1" />
                            <link key="favicon" rel="icon" href="/favicon.ico" />
                        </Head>
                        <div className="flex w-full">
                            <Sider width={240} className="pb-24 hidden lg:block bg-white" theme="light">
                                <Link href={link}>
                                    <Image preview={false} src="/assets/images/adaptist-consulting.png" alt="Adaptist Logo" />
                                </Link>
                                <ConfigProvider theme={{
                                    components: {
                                        Menu: {
                                            // https://github.com/ant-design/ant-design/blob/5.0.0/components/menu/style/theme.tsx#L133
                                            colorItemBg: '#3788FD',
                                            colorSubItemBg: 'white',
                                            colorItemTextSelected: '#3788FD',
                                            colorItemBgSelected: 'white',
                                            fontFamily: 'var(--font-inter)',
                                            colorBorder: 'white',
                                            fontSize: 13,
                                            
                                            // // https://github.com/ant-design/ant-design/blob/5.0.0/components/menu/style/theme.tsx#L194
                                            activeBarBorderWidth: 0,

                                            // https://github.com/ant-design/ant-design/blob/5.0.0/components/menu/style/theme.tsx#L133
                                            subMenuItemBg: 'white',
                                        },
                                    }
                                }}>
                                    <Menu
                                        className="custom-arrow-white"
                                        mode="inline" 
                                        items={getMenu()}
                                        selectedKeys={selected} 
                                        onSelect={e => setSelected(e.selectedKeys)} />
                                </ConfigProvider>
                            </Sider>
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Layout: {
                                            colorBgBase: 'red'
                                        }
                                    }
                                }}
                            >
                            </ConfigProvider>
                            <Layout className="flex flex-col h-screen">
                                <Layout.Header className="w-full bg-[#3788FD]">
                                    <div className="flex justify-end gap-4 text-white items-center h-full text-xl">
                                        {session?.expires && <p className="text-sm">Hello, {session.user?.name}</p>}
                                        <button>
                                            <FontAwesomeIcon icon={faBell} />
                                        </button>
                                        <button onClick={() => router.push('/user-management')} title="Manage Users">
                                            <FontAwesomeIcon icon={faUserGear} />
                                        </button>
                                        {status === 'authenticated' ? (
                                            <button onClick={() => handleLogout()} title="Logout">
                                                <FontAwesomeIcon icon={faSignOut} />
                                            </button>
                                        ) : (
                                            <button onClick={() => signIn("oidc")} title="Sign in">
                                                <FontAwesomeIcon icon={faSignIn} />
                                            </button>
                                        )}
                                    </div>
                                </Layout.Header>
                                <Layout.Content className="p-4 pb-0 overflow-y-auto">
                                    <MyBreadcrumb />
                                    {children}
                                </Layout.Content>
                            </Layout>
                        </div>
                    </Layout>
                </ConfigProvider>
            </div>
        </>
    );
}

export const WithDefaultLayout = (page: React.ReactElement) => <DefaultLayout>{page}</DefaultLayout>;
